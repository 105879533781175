import React from 'react'
import * as styles from './Books.module.css'

import Button from '../Button'
import { StaticImage } from 'gatsby-plugin-image'

export default class Books extends React.Component {
  render() {
    return (
      <aside className={styles.books}>
        <section className={styles.prompt}>
          <h3>Did Ya Know I've Written Some Books?</h3>
          <p>
            I have! The JavaScript-mancy series is the lovechild of three of my
            passions: <strong>JavaScript, writing and Fantasy</strong>. In the
            pages of each one of the books of the series you’ll find a breadth
            of JavaScript knowledge, delivered with a humorous and casual style
            of writing and sprinkled with Fantasy at every turn.
          </p>
          <p>
            They are the weirdest and quirkiest JavaScript books you'll ever
            find. <strong>There's nothing out there quite like it</strong>.
          </p>
          <Button
            darkpurple="true"
            to={'https://www.javascriptmancy.com'}
            target="_blank"
          >
            Tell Me More!!
          </Button>
        </section>
        <a
          className={styles.imagelink}
          href="https://www.javascriptmancy.com"
          target="_blank"
        >
          <StaticImage
            src="../../../static/images/javascriptmancy-cover-3d-only-front.png"
            alt="The JavaScriptmancy book cover with Randalf the Red teaching Mooleen the apprentice how to javascript with fireballs, and not having a lot of success, or perhaps mildly success judging for the numerous burn marks"
            title="JavaScript-mancy: Getting Started With the Arcane Art Of Writing Awesome JavaScript"
            width={800}
            height={920}
            quality={100}
            className={styles.bookImage}
          />
        </a>
      </aside>
    )
  }
}
