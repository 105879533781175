import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import get from 'lodash/get'

import Layout from './Layout'
import { ButtonPagination } from '../components/Button'
import Bio from '../components/bio/Bio'
import AgnosticLink from '../components/AgnosticLink'
import * as styles from './VSCodeVimBookChapter.module.css'

import { rhythm } from '../utils/typography'

class VSCodeVimBookChapter extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const { previous, next } = this.props.pageContext

    const { description, chapter } = post.frontmatter
    const { excerpt } = post

    const image = {
      src: '/images/boost-coding-fu-vscode-vim-banner.jpg',
      width: 1000,
      height: 420,
      alt: 'Boost your Coding Fu With VSCode and Vim',
    }

    return (
      <Layout>
        <main className={styles.container}>
          <article className={styles.article}>
            <SEO
              title={`${post.frontmatter.title} | ${siteTitle}`}
              description={description || excerpt}
              image={image}
            />

            <header className={styles.header}>
              <small className={styles.small}>Chapter {chapter}.</small>
              <small className={styles.small} style={{ float: 'right' }}>
                <AgnosticLink href="/boost-your-coding-fu-with-vscode-and-vim/table-of-contents">
                  Table of Contents
                </AgnosticLink>
              </small>
              <small className={styles.small} style={{ float: 'right' }}>
                <AgnosticLink href="/boost-your-coding-fu-with-vscode-and-vim/cheatsheet">
                  Cheatsheet
                </AgnosticLink>
              </small>
              <h1 style={{ paddingBottom: rhythm(1 / 2) }}>
                {post.frontmatter.title}
              </h1>
            </header>
            <div
              dangerouslySetInnerHTML={{ __html: post.html }}
              className={styles.content}
            />
            <footer className={styles.footer} style={{ marginTop: rhythm(2) }}>
              <ul className={styles.pagination}>
                {previous && (
                  <li className={styles.paginationitem}>
                    <ButtonPagination
                      to={previous.fields.slug}
                      rel="prev"
                      pixelated
                    >
                      ← Previous Chapter: {previous.frontmatter.title}
                    </ButtonPagination>
                  </li>
                )}

                {next && (
                  <li className={styles.paginationitem + ' ' + styles.next}>
                    <ButtonPagination
                      to={next.fields.slug}
                      rel="next"
                      pixelated
                    >
                      Next Chapter: {next.frontmatter.title} →
                    </ButtonPagination>
                  </li>
                )}
              </ul>
            </footer>
            <hr style={{ marginBottom: rhythm(1) }} />
            <Bio />
            <hr style={{ marginBottom: rhythm(1) }} />
          </article>
        </main>
      </Layout>
    )
  }
}

export default VSCodeVimBookChapter

export const pageQuery = graphql`
  query VSCodeVimChapterBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      excerpt
      timeToRead
      frontmatter {
        title
        date
        categories
        description
        chapter
        book
      }
    }
  }
`
