// extracted by mini-css-extract-plugin
export var background = "Layout-module--background--a75b2";
export var backgroundBottom = "Layout-module--background-bottom--d50fc";
export var banner = "Layout-module--banner--f9210";
export var base = "Layout-module--base--6be95";
export var bmc = "Layout-module--bmc--903f3";
export var content = "Layout-module--content--6bc10";
export var footer = "Layout-module--footer--cb2e2";
export var header = "Layout-module--header--e7f7a";
export var hero = "Layout-module--hero--24054";
export var image = "Layout-module--image--dd986";
export var presents = "Layout-module--presents--374d6";