import React from 'react'
import * as styles from './Layout.module.css'

// Code theme style
import 'prismjs/themes/prism-okaidia.css'

import '../fonts/Munro.ttf'
import './font-face.css'
import './styles.css'

import Newsletter from '../components/newsletter/Newsletter'
import Books from '../components/books/Books'
import Copyright from '../components/copyright/Copyright'
import AgnosticLink from '../components/AgnosticLink'
import { StaticImage } from 'gatsby-plugin-image'


class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <section className={styles.base + ' vscodevim'}>
        <header className={styles.header}>
          <div className={styles.banner}>
            <AgnosticLink href={'/boost-your-coding-fu-with-vscode-and-vim'}>
              <StaticImage
                src="../../static/images/boost-your-coding-fu-with-vscode-and-vim-long-banner-transparent.png"
                title="Boost Your Coding Fu With VSCode and Vim"
                alt="Boost Your Coding Fu With VSCode and Vim"
                layout="fullWidth"
                quality={100}
                className={styles.image}
                loading="eager"
                placeholder="none"
              />
            </AgnosticLink>
          </div>
        </header>
        <section className={styles.content}>{children}</section>
        <footer>
          <section className={styles.footer}>
            <p className={styles.hero}>FASTER. BETTER. STRONGER.</p>
          </section>
          <Newsletter />
          <Books />
          <Copyright />
        </footer>
      </section>
    )
  }
}

export default Layout
