// extracted by mini-css-extract-plugin
export var article = "VSCodeVimBookChapter-module--article--170e8";
export var basecolor = "#2e9cff";
export var container = "VSCodeVimBookChapter-module--container--b8350";
export var content = "VSCodeVimBookChapter-module--content--bb442";
export var date = "VSCodeVimBookChapter-module--date--02318";
export var footer = "VSCodeVimBookChapter-module--footer--8fe56";
export var header = "VSCodeVimBookChapter-module--header--05121";
export var highlightcolor = "#00cc6d";
export var next = "VSCodeVimBookChapter-module--next--85882";
export var pagination = "VSCodeVimBookChapter-module--pagination--c5d5f";
export var paginationitem = "VSCodeVimBookChapter-module--paginationitem--d3cb7";
export var readingtime = "VSCodeVimBookChapter-module--readingtime--aacd6";
export var small = "VSCodeVimBookChapter-module--small--087c2";