import React from 'react'
import * as styles from './Newsletter.module.css'

import Button from '../Button'

export default class Newsletter extends React.Component {
  render() {
    const { style = {}, theme = 'base' } = this.props

    // this is a travesty of using styles BTW
    // because I'm being freaking LAZY
    return (
      <footer
        className={`${styles.newsletter} ${styles[theme]}`}
        {...{ style }}
      >
        <section className={styles.promptmail}>
          <p className={styles.hero}>
            Would you like to receive more articles like this one on
            programming, web development, JavaScript, Angular, developer
            productivity, tools, UX and even exclusive content like free
            versions of my books in your mailbox? Then sign up to my super duper
            awesome inner circle.
          </p>
          {theme === 'base' && (
            <Button
              darkblue="true"
              to={'http://eepurl.com/bR8FHb'}
              target="_blank"
            >
              {' '}
              Sign Me Up!{' '}
            </Button>
          )}
          {theme === 'green' && (
            <Button
              darkgreen="true"
              to={'http://eepurl.com/bR8FHb'}
              target="_blank"
            >
              {' '}
              Sign Me Up!{' '}
            </Button>
          )}
        </section>
        <footer className={styles.promptrss}>
          Or if you hate e-mail and love RSS instead then{' '}
          <a
            className={styles.link}
            href="http://feeds.feedburner.com/BarbarianMeetsCoding"
          >
            subscribe to the RSS feed
          </a>{' '}
          like we're back in the 90s.{' '}
          <a
            className={styles.link}
            href="https://www.youtube.com/watch?v=rog8ou-ZepE"
            target="_blank"
          >
            Ice Ice Baby!
          </a>{' '}
          Din din din Din di di din Ding.
        </footer>
      </footer>
    )
  }
}
