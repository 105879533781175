// extracted by mini-css-extract-plugin
export var base = "Newsletter-module--base--d2fcc";
export var blue = "#067ee0";
export var darkgreen = "#00994f";
export var green = "#00b35f";
export var green2 = "#00cc6d";
export var greenish = "Newsletter-module--greenish--8527c";
export var hero = "Newsletter-module--hero--b5812";
export var link = "Newsletter-module--link--1e093";
export var newsletter = "Newsletter-module--newsletter--b700e";
export var promptmail = "Newsletter-module--promptmail--a4b2f";
export var promptrss = "Newsletter-module--promptrss--bf82b";
export var purple = "#673ab7";